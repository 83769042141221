
import { useState,useEffect } from 'react';

async function getConcert() {
    const response = await fetch('https://jeziorakyachtclub.octupus.pl/api/impreza/public/647f1829889922f28b8f9a4b');
    const data = await response.json();
    return data;
}

async function save(from: any) {
    const response = await fetch('https://jeziorakyachtclub.octupus.pl/api/bookingOthersPublic/', {
        method: 'POST',
        //  mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(from)

    }
    )
    const data = await response.json();
    return data;
}


async function getClientForm() {
    const response = await fetch('https://jeziorakyachtclub.octupus.pl/api/pageGen/by/', {
        method: 'POST',
//  mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "slug": "formImprezy" })
    }
    )
    const data = await response.json();
    return data;
}


const MainPage = () =>
{

    const [clientForm, setClientForm] = useState<any>({});
    const [concert, setConcert] = useState<any>({});
    const [sumaZamowienia, setSumaZamowienia] = useState<number>(0);
    const [order, setOrder] = useState<any>({});
    const [countOder, setCountOrder] = useState<any>({});
    const [personalData, setPersonalData] = useState<any>({});
    const [maxtoBook, setMaxtoBook] = useState<any>({});
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [bookingNumber, setBookingNumber] = useState<string>('');

    useEffect(() => {
        const clientFormData = getClientForm();
        clientFormData.then((data) => {

            setClientForm(data[0]);
        });

        const concertData = getConcert();
        concertData.then((data) => {

            setMaxtoBook(parseInt(data[0]['blocks']['main'][0]['max'])-parseInt(data[0]['booked'][0]['suma']));

            setConcert(data[0]);
        });
    }, []);

    const setItem = (value: any, id: any) => {

            concert?.blocks?.details.map((position: any) => {
                if (position.id == id)
                {
                    value = parseInt(value);
                    setCountOrder({...countOder, [position.id]: value });
                    setOrder({...order, [position.id]: position.cena * value });

                }
            })

    }

    const getValueItem = (id: any) => {
        if (countOder[id]) {
            return countOder[id];
        }
        return ''
    }

    useEffect(() => {
        let suma = 0;
        Object.keys(order).map((key) => {
            suma += order[key];
        })
        setSumaZamowienia(suma);
    }, [order]);


    const setPersonalDataItem = (value: any, id: any) => {

        setPersonalData({ ...personalData, [id]: value });
    }

    const [error, setError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>('');


    const validateAndSave = () => {


        let error_local=false;
        //validate
        setErrorText('');
        setError(false);

        const pd=clientForm?.pgSchema.find((el: any) => {

            return el.mainBlock.name=="personalData"
        })

        const zgody=clientForm?.pgSchema.find((el: any) => {

            return el.mainBlock.name=="zgody"
        })


        const keys:any=[]


        pd.mainBlock.blocks.map((el: any) => {
            el.block.items.map((item: any) => {
                if (item.required == true) {
                    keys.push(item.name)
                }
            })
        })

        zgody.mainBlock.blocks.map((el: any) => {
            el.block.items.map((item: any) => {
                if (item.required == true) {
                    keys.push(item.name)
                }

            })
        })



        keys.map((key:any) => {
            if (personalData[key] == '' || personalData[key] == undefined) {
                setErrorText('Wypełnij wszystkie pola');
                setError(true);
                error_local=true;
            }
        })



        const sumOfOrder = Object.keys(countOder).map((key) => {
            return countOder[key];
        }).reduce((a, b) => a + b, 0);

        if (sumOfOrder > maxtoBook) {
            setErrorText('Przekroczono ilość miejsc');
            setError(true);
            error_local=true;
        }



        if (error_local == false) {

        let data = {
            finanse: [] as any[],
            personalData: [] as any[]
        }

        let finanse: { [key: string]: any } = {};

        concert?.blocks?.details.map((position: any) => {
            const nameFiled=position.slug.charAt(0).toUpperCase() + position.slug.slice(1)
            finanse[`ilosc${nameFiled}`] = countOder[position.id];
            finanse[position.slug] = position.cena;
            finanse['suma']=sumaZamowienia;
        })

        data.finanse.push(finanse);
        data.personalData.push(personalData)
        save(data).then((resp) => {
            if (resp.status !== 'error') {
                setIsComplete(true);
                setBookingNumber(resp.status);
            } else {
                setErrorText('Wystąpił błąd! Sprawdż czy podany adres email jest prawidłowy w przeciwnym razie skontaktuj się z nami');
                setError(true);
            }
        })
        }









    }



    return (
        <div className="bg-white w-full max-w-[1366px] p-[3em]">
            <div className="flex py-10 justify-center">
             <a href="https://jeziorakyachtclub.pl/" title="log" target='_blank'>  <img src="https://jeziorakyachtclub.pl/wp-content/uploads/2020/06/jyc-logo-500px-horizontal.png" alt="logo" /></a>
            </div>
            <h1 className="font-bold py-[0.5em] text-[2em]">Rezerwacja</h1>
            <h2 className="font-bold pb-[0.2em] text-[2em]">Koncert - {concert?.blocks?.main[0].name}</h2>
            <h3>Miejsce: {concert?.blocks?.main[0].palce}</h3>
            <h3> Data: {concert?.blocks?.main[0].data}</h3>

            {isComplete == false && (
                <>
                    <div className='py-2 bg-gray-100 px-5 my-5 ' dangerouslySetInnerHTML={{ __html: concert?.blocks?.main[0].zajawka || '' }} />
                    <section className="form">
                        <section className="positionChoose">
                            <h3 className="font-bold pb-[0.2em] mt-10 text-[1.2em]">Wybierz opcje</h3>
                            <div className="grid gap-y-5 mt-5">
                                {
                                    concert?.blocks?.details.map((position: any, index: number) => {
                                        return (
                                            <div className="grid grid-cols-2 gap-10" key={index}>
                                                <div>{position.nazwa} - {position.cena} zł</div>
                                                <div> <input type="number" step="1" min="0" value={getValueItem(position.id)} className="outline-none border-b-2 w-full" placeholder="ilość miejsc" onChange={(e) => { setItem(e.target.value, position.id) }} /> </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div>
                                <h4 className="py-10 font-bold text-[1.2em]">Dane osoby zamawiającej</h4>
                                <>
                                    {


                                        clientForm?.pgSchema?.map((sections: any, index: number) => {


                                            return sections.mainBlock.blocks.map((block: any, index2: number) => {
                                                return (
                                                    <div className="grid md:grid-cols-2 gap-10">
                                                        {
                                                            block.block.items.map((field: any, index2: number) => {

                                                                if (field.input == "text") {

                                                                    return (

                                                                        <div className="grid gap-2 py-5 items-center" key={index2}>
                                                                            <div>{field.label}</div>

                                                                            <div> <input type="text" className="outline-none border-b-2 border-black w-full" onChange={
                                                                                (e) => {
                                                                                    setPersonalDataItem(e.target.value, field.name)
                                                                                }
                                                                            } /> </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                if (field.input == "checkbox") {
                                                                    return (

                                                                        <div className="flex gap-5 py-5 items-center" key={index2}>

                                                                            <div> <input type="checkbox" className="outline-none border-b-2 border-black" onChange={
                                                                                (e) => {
                                                                                    setPersonalDataItem(e.target.checked, field.name)
                                                                                }
                                                                            } /> </div >
                                                                            <div dangerouslySetInnerHTML={{__html:field.label}}/>
                                                                        </div>
                                                                    )
                                                                }

                                                            })


                                                        }
                                                    </div>)
                                            }
                                            )
                                        }
                                        )
                                    }
                                </>
                            </div>



                            {error && <div className="bg-red-500 text-white  p-5 my-5">{errorText}</div>}
                            <div className="flex gap-5 py-5 items-center justify-between">
                                <div className="text-[2xl] flex justify-end">Suma zamówienia: {sumaZamowienia} zł</div>

                                <button className="bg-[#262e49] text-white px-10 py-5 mt-10" onClick={validateAndSave}>Zamawiam i płacę</button>
                            </div>

                        </section>

                    </section>

                </>
            )}
            {isComplete && (
                <div className="text-center">
                    <h1 className="font-bold text-[2em]">Dziękujemy za dokonanie rezerwacji</h1>
                    <h2 className="font-bold text-[2em]">Numer Twojej rezerwacji to: {bookingNumber}</h2>
                    <h3 className="font-bold text-[2em]">Na podany adres email wysłaliśmy potwierdzenie rezerwacji oraz informacje dotyczące płatności</h3>
                </div>
            )}

            <div className="flex justify-between items-center font-bold mt-10"><div><strong>Jeziorak Yacht Club </strong><br/>
Obrońców Westerplatte 4/19<br/>
14-200 Iława<br/></div> <div className="flex"><div>tel. 533 275 024<br/></div></div> <div><div className="flex"><div><a href="mailto:biuro@jeziorakyachtclub.pl">biuro@jeziorakyachtclub.pl</a></div></div></div></div>
</div>
    )
}

export default MainPage